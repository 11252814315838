/*
 *
 *
 *
 *
 *
 *         WARNING: Editing this file may prevent future updates.
 *                  To maintain easy upgradability, do not edit this file.
 *                  To import a module in AppModule, please use app-module-imports.ts instead.
 *
 *
 *
 *
 *
 */
import { HttpClientModule, HttpClientXsrfModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { StepUpInterceptor } from '@backbase/identity-auth/step-up';
import { NOTIFICATIONS_BADGE_NOTIFICATIONS_BASE_PATH } from '@backbase/notifications-ang';
import { SharedUserContextInterceptor } from '@backbase/shared/feature/user-context';
import { initProviders } from '@backbase/shared/util/app-core';
import { BB_TIMEZONE_CONFIG_TOKEN } from '@backbase/ui-ang/date-timezone-transformation';
import { environment } from '../environments/environment';
import { appModuleImports } from './app-module-imports';
import { AppComponent } from './app.component';
import { APP_NOTIFICATIONS_BASE_PATH, ServicePathsModule } from './service-paths.module';
import { AMOUNT_CONFIG_TOKEN } from '@backbase/ui-ang/amount';
import { MOCKS_TOKEN } from '@backbase/shared/feature/auth';
import { RemoteConfigService } from '@backbase/remote-config-ang';
import { BusinessAppRemoteConfig } from '@backbase/business/feature/layout';
import { TitleStrategy } from '@angular/router';
import {
  APPLICATION_NAME,
  APPLICATION_VERSION,
  APPLICATION_VERSION_HEADER,
  AppVersionInterceptor,
  BreadcrumbTitleStrategy,
} from '@backbase/foundation-ang/core';

import appInfo from '../../version.json';
import { AMOUNT_CONFIG } from './app.config';

export function applicationInitializer(remoteConfig: RemoteConfigService<BusinessAppRemoteConfig>) {
  return () => remoteConfig.fetchAndActivate();
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    HttpClientXsrfModule,
    environment.animation ? BrowserAnimationsModule : NoopAnimationsModule,
    ServicePathsModule,
    ...appModuleImports,
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'en',
    },
    {
      provide: APP_INITIALIZER,
      useFactory: applicationInitializer,
      multi: true,
      deps: [RemoteConfigService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SharedUserContextInterceptor,
      multi: true,
    },
    {
      provide: NOTIFICATIONS_BADGE_NOTIFICATIONS_BASE_PATH,
      useExisting: APP_NOTIFICATIONS_BASE_PATH,
    },
    {
      provide: APPLICATION_VERSION_HEADER,
      useValue: environment.versionHeaderName,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: StepUpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppVersionInterceptor,
      multi: true,
    },
    {
      provide: BB_TIMEZONE_CONFIG_TOKEN,
      useValue: 'Europe/Lisbon',
    },
    initProviders.inputEmailConfig,
    initProviders.inputPhoneConfig,
    initProviders.countryCodeFormatConfig,
    {
      provide: AMOUNT_CONFIG_TOKEN,
      useValue: { mapCurrency: false },
    },
    {
      provide: MOCKS_TOKEN,
      useValue: environment.mocksEnabled,
    },
    { provide: TitleStrategy, useClass: BreadcrumbTitleStrategy },
    {
      provide: APPLICATION_NAME,
      useValue: $localize`:The name of the application@@bb.banking-app.application-name:Business Banking`,
    },
    {
      provide: APPLICATION_VERSION,
      useValue: appInfo.version,
    },
    {
      provide: AMOUNT_CONFIG_TOKEN,
      useValue: AMOUNT_CONFIG,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
