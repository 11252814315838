<nav>
  <ul class="bb-layout__vertical-nav">
    <li class="bb-layout__vertical-nav-section" *bbIfEntitlements="permissions.canViewDashboard">
      <ul class="bb-layout__vertical-nav">
        <li class="bb-layout__vertical-nav-item">
          <a
            routerLink="/dashboard"
            routerLinkActive="bb-layout__vertical-nav-item-link--active"
            class="bb-layout__vertical-nav-item-link"
            aria-label="Dashboard"
            i18n-aria-label="Dashboard navigation button aria-label@@dashboard.nav.item.area-label"
            #accountsRouterLink="routerLinkActive"
            [attr.aria-current]="accountsRouterLink.isActive ? 'page' : undefined"
            (click)="focusHandler.skipToContent($event)"
            (keydown.space)="focusHandler.handleKeyboardEvent($event)"
          >
            <bb-icon-ui class="bb-layout__vertical-nav-item-icon" name="dashboard"></bb-icon-ui>
            <span class="bb-layout__vertical-nav-item-title" i18n="@@dashboard.nav.item.title">Dashboard</span>
          </a>
        </li>
      </ul>
    </li>
    <li class="bb-layout__vertical-nav-separator" role="separator"></li>

    <li class="bb-layout__vertical-nav-section" *bbIfEntitlements="permissions.canViewAccountsAndCards">
      <div class="bb-layout__vertical-nav-header">
        <bb-header-ui
          class="bb-inline-stack__item bb-text-support"
          headingClasses="bb-layout__vertical-nav-header-title"
          headingType="h3"
          heading=" ACCOUNTS & CARDS "
        ></bb-header-ui>
      </div>
      <ul class="bb-layout__vertical-nav">
        <li class="bb-layout__vertical-nav-item" *bbIfEntitlements="permissions.canViewAccounts">
          <a
            routerLink="/accounts"
            routerLinkActive="bb-layout__vertical-nav-item-link--active"
            class="bb-layout__vertical-nav-item-link"
            aria-label="Accounts"
            #accountsRouterLink="routerLinkActive"
            [attr.aria-current]="accountsRouterLink.isActive ? 'page' : undefined"
            (click)="focusHandler.skipToContent($event)"
            (keydown.space)="focusHandler.handleKeyboardEvent($event)"
          >
            <bb-icon-ui class="bb-layout__vertical-nav-item-icon" name="accounts"></bb-icon-ui>
            <span class="bb-layout__vertical-nav-item-title" i18n="@@accounts.nav.item.title">Accounts</span>
          </a>
        </li>
        <li class="bb-layout__vertical-nav-item" *bbIfEntitlements="permissions.canViewCards">
          <a
            routerLink="/cards"
            routerLinkActive="bb-layout__vertical-nav-item-link--active"
            class="bb-layout__vertical-nav-item-link"
            aria-label="Cards"
            #cardsRouterLink="routerLinkActive"
            [attr.aria-current]="cardsRouterLink.isActive ? 'page' : undefined"
            (click)="focusHandler.skipToContent($event)"
            (keydown.space)="focusHandler.handleKeyboardEvent($event)"
          >
            <bb-icon-ui class="bb-layout__vertical-nav-item-icon" name="credit-card"></bb-icon-ui>
            <span class="bb-layout__vertical-nav-item-title" i18n="@@cards.nav.item.title">Cards</span>
          </a>
        </li>
        <li class="bb-layout__vertical-nav-item" *bbIfEntitlements="permissions.canViewLoans">
          <a
            routerLink="/loans"
            routerLinkActive="bb-layout__vertical-nav-item-link--active"
            class="bb-layout__vertical-nav-item-link"
            aria-label="Loans"
            #loansRouterLink="routerLinkActive"
            [attr.aria-current]="loansRouterLink.isActive ? 'page' : undefined"
            (click)="focusHandler.skipToContent($event)"
            (keydown.space)="focusHandler.handleKeyboardEvent($event)"
          >
            <bb-icon-ui class="bb-layout__vertical-nav-item-icon" name="loans"></bb-icon-ui>
            <span class="bb-layout__vertical-nav-item-title" i18n="@@loans.nav.item.title">Loans</span>
          </a>
        </li>
        <li class="bb-layout__vertical-nav-item" *bbIfEntitlements="permissions.canViewAccountStatements">
          <a
            routerLink="/account-statements"
            routerLinkActive="bb-layout__vertical-nav-item-link--active"
            class="bb-layout__vertical-nav-item-link"
            aria-label="Account Statements"
            #accountStatementsRouterLink="routerLinkActive"
            [attr.aria-current]="accountStatementsRouterLink.isActive ? 'page' : undefined"
            (click)="focusHandler.skipToContent($event)"
            (keydown.space)="focusHandler.handleKeyboardEvent($event)"
          >
            <bb-icon-ui class="bb-layout__vertical-nav-item-icon" name="description"></bb-icon-ui>
            <span class="bb-layout__vertical-nav-item-title" i18n="@@account-statements.nav.item.title"
              >Account Statements</span
            >
          </a>
        </li>
        <li class="bb-layout__vertical-nav-item" *bbIfEntitlements="permissions.canViewTransactions">
          <a
            routerLink="/transactions"
            routerLinkActive="bb-layout__vertical-nav-item-link--active"
            class="bb-layout__vertical-nav-item-link"
            aria-label="Transactions"
            #transactionsRouterLink="routerLinkActive"
            [attr.aria-current]="transactionsRouterLink.isActive ? 'page' : undefined"
            (click)="focusHandler.skipToContent($event)"
            (keydown.space)="focusHandler.handleKeyboardEvent($event)"
          >
            <bb-icon-ui class="bb-layout__vertical-nav-item-icon" name="transactions"></bb-icon-ui>
            <span class="bb-layout__vertical-nav-item-title" i18n="@@transactions.nav.item.title">Transactions</span>
          </a>
        </li>
      </ul>
    </li>

    <li
      class="bb-layout__vertical-nav-separator"
      role="separator"
      *bbIfEntitlements="permissions.canViewAccountsAndCards"
    ></li>
    <li class="bb-layout__vertical-nav-section" *bbIfEntitlements="permissions.canViewMoveMoney">
      <div class="bb-layout__vertical-nav-header">
        <bb-header-ui
          class="bb-inline-stack__item bb-text-support"
          headingClasses="bb-layout__vertical-nav-header-title"
          headingType="h3"
          heading=" MOVE MONEY "
        ></bb-header-ui>
      </div>
      <ul class="bb-layout__vertical-nav">
        <li class="bb-layout__vertical-nav-item" *bbIfEntitlements="permissions.canViewTransfers">
          <a
            routerLink="/transfers"
            routerLinkActive="bb-layout__vertical-nav-item-link--active"
            class="bb-layout__vertical-nav-item-link"
            aria-label="Transfers"
            #transfersRouterLink="routerLinkActive"
            [attr.aria-current]="transfersRouterLink.isActive ? 'page' : undefined"
            (click)="focusHandler.skipToContent($event)"
            (keydown.space)="focusHandler.handleKeyboardEvent($event)"
          >
            <bb-icon-ui class="bb-layout__vertical-nav-item-icon" name="payments"></bb-icon-ui>
            <span class="bb-layout__vertical-nav-item-title" i18n="@@transfers.nav.item.title">Transfers</span>
          </a>
        </li>
        <li class="bb-layout__vertical-nav-item" *bbIfEntitlements="permissions.canViewBatches">
          <a
            routerLink="/batches"
            routerLinkActive="bb-layout__vertical-nav-item-link--active"
            class="bb-layout__vertical-nav-item-link"
            aria-label="Batches"
            #batchesRouterLink="routerLinkActive"
            [attr.aria-current]="batchesRouterLink.isActive ? 'page' : undefined"
            (click)="focusHandler.skipToContent($event)"
            (keydown.space)="focusHandler.handleKeyboardEvent($event)"
          >
            <bb-icon-ui class="bb-layout__vertical-nav-item-icon" name="batches"></bb-icon-ui>
            <span class="bb-layout__vertical-nav-item-title" i18n="@@batches.nav.item.title">Batches</span>
          </a>
        </li>
        <li class="bb-layout__vertical-nav-item" *bbIfEntitlements="permissions.canViewStopChecks">
          <a
            routerLink="/stop-check-payments"
            routerLinkActive="bb-layout__vertical-nav-item-link--active"
            class="bb-layout__vertical-nav-item-link"
            aria-label="Stop check payments"
            #stopCheckRouterLink="routerLinkActive"
            [attr.aria-current]="stopCheckRouterLink.isActive ? 'page' : undefined"
            (click)="focusHandler.skipToContent($event)"
            (keydown.space)="focusHandler.handleKeyboardEvent($event)"
          >
            <bb-icon-ui class="bb-layout__vertical-nav-item-icon" name="cancel-presentation"></bb-icon-ui>
            <span class="bb-layout__vertical-nav-item-title" i18n="@@stop-check.nav.item.title"
              >Stop check payments</span
            >
          </a>
        </li>
        <li class="bb-layout__vertical-nav-item" *bbIfEntitlements="permissions.canViewPaymentTemplates">
          <a
            routerLink="/templates"
            routerLinkActive="bb-layout__vertical-nav-item-link--active"
            class="bb-layout__vertical-nav-item-link"
            aria-label="Templates"
            #templatesRouterLink="routerLinkActive"
            [attr.aria-current]="templatesRouterLink.isActive ? 'page' : undefined"
            (click)="focusHandler.skipToContent($event)"
            (keydown.space)="focusHandler.handleKeyboardEvent($event)"
          >
            <bb-icon-ui class="bb-layout__vertical-nav-item-icon" name="file-copy"></bb-icon-ui>
            <span class="bb-layout__vertical-nav-item-title" i18n="@@templates.nav.item.title">Templates</span>
          </a>
        </li>
        <li class="bb-layout__vertical-nav-item" *bbIfEntitlements="permissions.canViewContacts">
          <a
            routerLink="/contacts"
            routerLinkActive="bb-layout__vertical-nav-item-link--active"
            class="bb-layout__vertical-nav-item-link"
            aria-label="Contacts"
            #contactsRouterLink="routerLinkActive"
            [attr.aria-current]="contactsRouterLink.isActive ? 'page' : undefined"
            (click)="focusHandler.skipToContent($event)"
            (keydown.space)="focusHandler.handleKeyboardEvent($event)"
          >
            <bb-icon-ui class="bb-layout__vertical-nav-item-icon" name="contacts"></bb-icon-ui>
            <span class="bb-layout__vertical-nav-item-title" i18n="@@contacts.nav.item.title">Contacts</span>
          </a>
        </li>
      </ul>
    </li>

    <li
      class="bb-layout__vertical-nav-separator"
      role="separator"
      *bbIfEntitlements="permissions.canViewMoveMoney"
    ></li>
    <li class="bb-layout__vertical-nav-section">
      <div class="bb-layout__vertical-nav-header">
        <bb-header-ui
          class="bb-inline-stack__item bb-text-support"
          headingClasses="bb-layout__vertical-nav-header-title"
          headingType="h3"
          heading=" FINANCE MANAGEMENT "
        ></bb-header-ui>
      </div>
      <ul class="bb-layout__vertical-nav">
        <li class="bb-layout__vertical-nav-item">
          <a
            routerLink="/cash-flow"
            routerLinkActive="bb-layout__vertical-nav-item-link--active"
            class="bb-layout__vertical-nav-item-link"
            aria-label="Cash Flow"
            #cashFlowRouterLink="routerLinkActive"
            [attr.aria-current]="cashFlowRouterLink.isActive ? 'page' : undefined"
            (click)="focusHandler.skipToContent($event)"
            (keydown.space)="focusHandler.handleKeyboardEvent($event)"
          >
            <bb-icon-ui class="bb-layout__vertical-nav-item-icon" name="currency-trade"></bb-icon-ui>
            <span class="bb-layout__vertical-nav-item-title" i18n="@@cash-flow.nav.item.title">Cash Flow</span>
          </a>
        </li>
      </ul>
    </li>

    <li class="bb-layout__vertical-nav-separator" role="separator"></li>
    <li class="bb-layout__vertical-nav-section">
      <div class="bb-layout__vertical-nav-header">
        <bb-header-ui
          class="bb-inline-stack__item bb-text-support"
          headingClasses="bb-layout__vertical-nav-header-title"
          headingType="h3"
          heading=" PERSONAL "
        ></bb-header-ui>
      </div>
      <ul class="bb-layout__vertical-nav">
        <li *bbIfEntitlements="permissions.canViewMessages" class="bb-layout__vertical-nav-item">
          <a
            routerLink="/messages"
            routerLinkActive="bb-layout__vertical-nav-item-link--active"
            class="bb-layout__vertical-nav-item-link"
            aria-label="Messages"
            #messagesRouterLink="routerLinkActive"
            [attr.aria-current]="messagesRouterLink.isActive ? 'page' : undefined"
            (click)="focusHandler.skipToContent($event)"
            (keydown.space)="focusHandler.handleKeyboardEvent($event)"
          >
            <bb-icon-ui class="bb-layout__vertical-nav-item-icon" name="mail"></bb-icon-ui>
            <span class="bb-layout__vertical-nav-item-title" i18n="@@messages.nav.item.title">Messages</span>
          </a>
        </li>
        <ng-container *ngIf="chatPanelLink">
          <li *bbIfEntitlements="permissions.canViewRealTimeCommunicationChat">
            <ng-container *ngComponentOutlet="chatPanelLink | async"></ng-container>
          </li>
        </ng-container>
        <li class="bb-layout__vertical-nav-item">
          <a
            routerLink="/my-profile"
            routerLinkActive="bb-layout__vertical-nav-item-link--active"
            class="bb-layout__vertical-nav-item-link"
            aria-label="My Profile"
            #myProfileRouterLink="routerLinkActive"
            [attr.aria-current]="myProfileRouterLink.isActive ? 'page' : undefined"
            (click)="focusHandler.skipToContent($event)"
            (keydown.space)="focusHandler.handleKeyboardEvent($event)"
          >
            <bb-icon-ui class="bb-layout__vertical-nav-item-icon" name="user"></bb-icon-ui>
            <span class="bb-layout__vertical-nav-item-title" i18n="@@my-profile.nav.item.title">My profile</span>
          </a>
        </li>
        <li class="bb-layout__vertical-nav-item">
          <a
            routerLink="/find-atm-branch"
            routerLinkActive="bb-layout__vertical-nav-item-link--active"
            class="bb-layout__vertical-nav-item-link"
            aria-label="Find ATM or Branch"
            #placesRouterLink="routerLinkActive"
            [attr.aria-current]="placesRouterLink.isActive ? 'page' : undefined"
            (click)="focusHandler.skipToContent($event)"
            (keydown.space)="focusHandler.handleKeyboardEvent($event)"
          >
            <bb-icon-ui class="bb-layout__vertical-nav-item-icon" name="location-on"></bb-icon-ui>
            <span class="bb-layout__vertical-nav-item-title" i18n="@@find-atm-branch.nav.item.title"
              >Find ATM or Branch</span
            >
          </a>
        </li>
      </ul>
    </li>

    <li class="bb-layout__vertical-nav-separator" role="separator"></li>
    <li class="bb-layout__vertical-nav-section" *bbIfEntitlements="permissions.canViewCompanyAdministration">
      <div class="bb-layout__vertical-nav-header">
        <bb-header-ui
          class="bb-inline-stack__item bb-text-support"
          headingClasses="bb-layout__vertical-nav-header-title"
          headingType="h3"
          heading=" COMPANY ADMINISTRATION "
        ></bb-header-ui>
      </div>
      <ul class="bb-layout__vertical-nav">
        <li class="bb-layout__vertical-nav-item" *bbIfEntitlements="permissions.canViewAudit">
          <a
            routerLink="/audit"
            routerLinkActive="bb-layout__vertical-nav-item-link--active"
            class="bb-layout__vertical-nav-item-link"
            aria-label="Audit"
            #auditPayRouterLink="routerLinkActive"
            [attr.aria-current]="auditPayRouterLink.isActive ? 'page' : undefined"
            (click)="focusHandler.skipToContent($event)"
            (keydown.space)="focusHandler.handleKeyboardEvent($event)"
          >
            <bb-icon-ui class="bb-layout__vertical-nav-item-icon" name="audit"></bb-icon-ui>
            <span class="bb-layout__vertical-nav-item-title" i18n="@@audit.nav.item.title">Audit</span>
          </a>
        </li>
        <li class="bb-layout__vertical-nav-item" *bbIfEntitlements="permissions.canViewCompanyPermissions">
          <a
            routerLink="/company-permissions"
            routerLinkActive="bb-layout__vertical-nav-item-link--active"
            class="bb-layout__vertical-nav-item-link"
            aria-label="Company Permissions"
            #companyPermissionsPayRouterLink="routerLinkActive"
            [attr.aria-current]="companyPermissionsPayRouterLink.isActive ? 'page' : undefined"
            (click)="focusHandler.skipToContent($event)"
            (keydown.space)="focusHandler.handleKeyboardEvent($event)"
          >
            <bb-icon-ui class="bb-layout__vertical-nav-item-icon" name="users"></bb-icon-ui>
            <span class="bb-layout__vertical-nav-item-title" i18n="@@company-permissions.nav.item.title"
              >Company Permissions</span
            >
          </a>
        </li>
        <li class="bb-layout__vertical-nav-item" *bbIfEntitlements="permissions.canViewServiceAgreements">
          <a
            routerLink="/service-agreements"
            routerLinkActive="bb-layout__vertical-nav-item-link--active"
            class="bb-layout__vertical-nav-item-link"
            aria-label="Service Agreements"
            #serviceAgreementsPayRouterLink="routerLinkActive"
            [attr.aria-current]="serviceAgreementsPayRouterLink.isActive ? 'page' : undefined"
            (click)="focusHandler.skipToContent($event)"
            (keydown.space)="focusHandler.handleKeyboardEvent($event)"
          >
            <bb-icon-ui class="bb-layout__vertical-nav-item-icon" name="ballot"></bb-icon-ui>
            <span class="bb-layout__vertical-nav-item-title" i18n="@@service-agreements.nav.item.title"
              >Service Agreements</span
            >
          </a>
        </li>
        <li class="bb-layout__vertical-nav-item" *bbIfEntitlements="permissions.canViewLegalEntities">
          <a
            routerLink="/legal-entities"
            routerLinkActive="bb-layout__vertical-nav-item-link--active"
            class="bb-layout__vertical-nav-item-link"
            aria-label="Legal Entities"
            #legalEntitiesPayRouterLink="routerLinkActive"
            [attr.aria-current]="legalEntitiesPayRouterLink.isActive ? 'page' : undefined"
            (click)="focusHandler.skipToContent($event)"
            (keydown.space)="focusHandler.handleKeyboardEvent($event)"
          >
            <bb-icon-ui class="bb-layout__vertical-nav-item-icon" name="domain"></bb-icon-ui>
            <span class="bb-layout__vertical-nav-item-title" i18n="@@legal-entities.nav.item.title"
              >Legal Entities</span
            >
          </a>
        </li>
        <li class="bb-layout__vertical-nav-item" *bbIfEntitlements="permissions.canViewGlobalMac">
          <a
            routerLink="/global-restrictions"
            routerLinkActive="bb-layout__vertical-nav-item-link--active"
            class="bb-layout__vertical-nav-item-link"
            aria-label="Global Restrictions"
            #globalRestrictionsRouterLink="routerLinkActive"
            [attr.aria-current]="globalRestrictionsRouterLink.isActive ? 'page' : undefined"
            (click)="focusHandler.skipToContent($event)"
            (keydown.space)="focusHandler.handleKeyboardEvent($event)"
          >
            <bb-icon-ui class="bb-layout__vertical-nav-item-icon" name="apps"></bb-icon-ui>
            <span class="bb-layout__vertical-nav-item-title" i18n="@@global-restrictions.nav.item.title"
              >Global Restrictions</span
            >
          </a>
        </li>
      </ul>
    </li>
  </ul>
</nav>
