import { Component } from '@angular/core';
import { PERMISSIONS } from '../../auth/permissions';
import { FocusHandlerService } from '@backbase/shared/util/app-core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'bb-navigation-menu',
  templateUrl: './navigation-menu.component.html',
})
export class NavigationMenuComponent {
  readonly permissions = PERMISSIONS;
  readonly chatPanelLink = environment.rtcEnabled
    ? import('@backbase/business/feature/rtc').then((c) => c.ChatPanelNavMenuItemComponent)
    : undefined;

  constructor(public readonly focusHandler: FocusHandlerService) {}
}
