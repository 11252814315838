<div class="bb-identity-journey__container">
  <div class="bb-identity-journey__background-container bb-stack">
    <div class="bb-identity-journey__background" aria-hidden="true"></div>
  </div>
  <div class="bb-identity-journey__body-container bb-stack" data-role="identity-journey-wrapper-body">
    <div class="container container--fixed-width-small bb-identity-journey__body">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
