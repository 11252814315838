import { Component } from '@angular/core';
import { ConditionsService } from '@backbase/foundation-ang/entitlements';
import { from, Observable } from 'rxjs';
import { PERMISSIONS } from '../auth/permissions';
import { environment } from '../../environments/environment';

@Component({
  templateUrl: './layout.component.html',
})
export class LayoutComponent {
  readonly permissions = PERMISSIONS;
  readonly chatPanel = environment.rtcEnabled
    ? import('@backbase/business/feature/rtc').then((c) => c.ChatPanelContainerComponent)
    : undefined;

  readonly displayNotificationSettingsButton$: Observable<boolean | undefined> = from(
    this.conditionService.resolveEntitlements(PERMISSIONS.canViewManageNotifications),
  );

  constructor(private conditionService: ConditionsService) {}
}
