import { AmountConfig } from '@backbase/ui-ang/amount';

export interface AppLevelConfig {
  paginationType: 'pagination' | 'load-more' | string;
  pageSize: number;
  transactionStatementMaxNavPages: number;
  [key: string]: unknown;
}

export const APP_CONFIG: AppLevelConfig = {
  paginationType: 'pagination',
  pageSize: 20,
  transactionStatementMaxNavPages: 5,
};

export const AMOUNT_CONFIG: AmountConfig = {
  signPosition: 'right',
};
