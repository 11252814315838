/*
 *
 *
 *
 *
 *
 *         WARNING: Editing this file may prevent future updates.
 *                  To maintain easy upgradability, do not edit this file.
 *
 *
 *
 *
 *
 */
import { Component } from '@angular/core';

@Component({
  selector: 'bb-app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {}
