<!--
/*
 *
 *
 *
 *
 *
 *         WARNING: Editing this file may prevent future updates.
 *                  To maintain easy upgradability, do not edit this file.
 *
 *
 *
 *
 *
 */
-->
<router-outlet></router-outlet>

<bb-activity-monitor></bb-activity-monitor>
<bb-routable-modal-view></bb-routable-modal-view>
<bb-transaction-signing>
  <ng-template bbReauthContextualHeader let-context="context">
    <bb-transaction-signing-header [data]="context"></bb-transaction-signing-header>
  </ng-template>
</bb-transaction-signing>
<bb-step-up></bb-step-up>
