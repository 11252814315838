<bb-business-layout [displayNotificationSettingsButton]="(displayNotificationSettingsButton$ | async) ?? false">
  <bb-navigation-menu></bb-navigation-menu>
  <router-outlet></router-outlet>

  <ng-container *ngIf="chatPanel">
    <ng-container *bbIfEntitlements="permissions.canViewRealTimeCommunicationChat">
      <ng-container *ngComponentOutlet="chatPanel | async"></ng-container>
    </ng-container>
  </ng-container>
</bb-business-layout>
