<nav>
  <ul class="bb-layout__vertical-nav">
    <li class="bb-layout__vertical-nav-section" *bbIfEntitlements="permissions.canViewAccountsAndCards">
      <div class="bb-layout__vertical-nav-header">
        <bb-header-ui
          class="bb-inline-stack__item bb-text-support"
          headingClasses="bb-layout__vertical-nav-header-title"
          headingType="h3"
          heading=" ACCOUNTS"
        ></bb-header-ui>
      </div>
      <ul class="bb-layout__vertical-nav">
        <li class="bb-layout__vertical-nav-item" *bbIfEntitlements="permissions.canViewAccounts">
          <a
            routerLink="/accounts"
            routerLinkActive="bb-layout__vertical-nav-item-link--active"
            class="bb-layout__vertical-nav-item-link"
            aria-label="Accounts"
            #accountsRouterLink="routerLinkActive"
            [attr.aria-current]="accountsRouterLink.isActive ? 'page' : undefined"
            (click)="focusHandler.skipToContent($event)"
            (keydown.space)="focusHandler.handleKeyboardEvent($event)"
          >
            <bb-icon-ui class="bb-layout__vertical-nav-item-icon" name="accounts"></bb-icon-ui>
            <span class="bb-layout__vertical-nav-item-title" i18n="@@accounts.nav.item.title">Accounts</span>
          </a>
        </li>
        <li class="bb-layout__vertical-nav-item" *bbIfEntitlements="permissions.canViewAccountStatements">
          <a
            routerLink="/account-statements"
            routerLinkActive="bb-layout__vertical-nav-item-link--active"
            class="bb-layout__vertical-nav-item-link"
            aria-label="Account Statements"
            #accountStatementsRouterLink="routerLinkActive"
            [attr.aria-current]="accountStatementsRouterLink.isActive ? 'page' : undefined"
            (click)="focusHandler.skipToContent($event)"
            (keydown.space)="focusHandler.handleKeyboardEvent($event)"
          >
            <bb-icon-ui class="bb-layout__vertical-nav-item-icon" name="description"></bb-icon-ui>
            <span class="bb-layout__vertical-nav-item-title" i18n="@@account-statements.nav.item.title"
              >Account Statements</span>
          </a>
        </li>
      </ul>
    </li>
  </ul>
</nav>
