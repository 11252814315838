import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LayoutService } from '@backbase/ui-ang/layout';
import { OAuthService } from 'angular-oauth2-oidc';
import { MenuFocusService } from '@backbase/shared/util/app-core';
import { RemoteConfigService } from '@backbase/remote-config-ang';
import { BusinessAppRemoteConfig } from './remote-config/remote-config';

@Component({
  selector: 'bb-business-layout',
  templateUrl: './business-layout.component.html',
})
export class BusinessLayoutComponent implements OnInit, OnDestroy {
  readonly notificationsAllowedRoutes =
    'conversation-view, arrangement-view, transaction-view, party-view, party-approve-view, payment-view, payment-approve-view, payments-list-view, audit-download-view, account-statements-archive-download-view, positive-pay-ach-active-exceptions-view, batch-view, batch-approve-view, positive-pay-check-active-exceptions-view';
  showMaintenanceBanner: boolean;
  maintenanceBannerText: unknown;
  @Input() displayNotificationSettingsButton!: boolean;

  constructor(
    public readonly layoutService: LayoutService,
    private readonly router: Router,
    private readonly oAuthService: OAuthService,
    private menuFocus: MenuFocusService,
    private remoteConfigService: RemoteConfigService<BusinessAppRemoteConfig>,
  ) {
    this.showMaintenanceBanner = this.remoteConfigService.getValue('show_maintenance_banner');
    this.maintenanceBannerText = this.remoteConfigService.getValue('maintenance_banner_text');
  }

  hideMaintenanceBanner() {
    this.showMaintenanceBanner = false;
  }
  ngOnInit(): void {
    this.menuFocus.initFocusHandler();
  }

  ngOnDestroy(): void {
    this.menuFocus.stopFocusHandler();
  }

  openNotificationSettings() {
    this.router.navigate(['self-service/product-list/manage-notifications']);
  }

  logout() {
    this.oAuthService.logOut();
  }
}
